import './App.css';
import ParticlesBackground from './components/ParticlesBackground';
import Header from './components/Header';
import Navbar from './components/Navbar';
import React, { useState } from 'react';
import Projects from './components/Projects';
import projects from './components/config/projects_info';
import experiences from './components/config/experience-info';
import About from './components/About';
import Contact from './components/Contact';
import testimonials from './components/config/testimonials_info';

function App() {


  const [isDarkMode, setIsDarkMode] = useState(true);
  const [selectedNav, setSelectedNav] = useState("about");

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  const selectNav = (nav) => {
    setSelectedNav(nav);
  }

  const getTab = (selectedTab) =>{
    switch(selectedTab){
      case 'about':
        return (
        <>
          <About />
          {
            testimonials.map((post, index) => (
              <Projects post={post} key={index} selectedTab={selectedTab}/>
            ))
          }
        </>)
        
      case 'projects':
        return projects.map((post, index) => (
          <Projects post={post} key={index} selectedTab={selectedTab}/>
        ))
      case 'experience':
        return experiences.map((post, index) => (
          <Projects post={post} key={index} selectedTab={selectedTab}/>
        ))
      case 'contact':
        return <Contact />;
      default:
        return '';
    }
  }


  return (
    <>
      <div className={`App ${isDarkMode ?'dark_mode' : '' }`}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode}/>
        <Navbar selectNav={selectNav} selectedNav={selectedNav} isDarkMode={isDarkMode}/>
        {getTab(selectedNav)}
      </div>
      
      <ParticlesBackground isDarkMode={isDarkMode}/>
    </>
  );
}

export default App;
