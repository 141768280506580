import React, {useState} from "react"
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import VerifiedIcon from '@mui/icons-material/Verified';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
// import DownloadIcon from '@mui/icons-material/Download';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Typewriter from 'typewriter-effect';
import '../css/Header.css'


const Header = ({toggleDarkMode, isDarkMode}) =>{
  const [isHovered, setIsHovered] = useState(false);


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
      width: 62,
      height: 34,
      padding: 7,
      '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
          color: '#fff',
          transform: 'translateX(22px)',
          '& .MuiSwitch-thumb:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
              '#fff',
            )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
          },
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
          },
        },
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
      },
      '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
      },
    }));

    // const downloadResume = () => {
    //   // using Java Script method to get PDF file
    //   fetch('jv_resume.pdf').then(response => {
    //       response.blob().then(blob => {
    //           // Creating new object of PDF file
    //           const fileURL = window.URL.createObjectURL(blob);
    //           // Setting various property values
    //           let alink = document.createElement('a');
    //           alink.href = fileURL;
    //           alink.download = 'Jason_Velarde_resume.pdf';
    //           alink.click();
    //       })
    //   })
    // }

    return (
        <>
            <div className='header_username'>
                <div className='header_username_text'>jamave-bot</div>
            </div>

            {/* */}
            {/* Header image */}
            <img className='header_image' src='https://pbs.twimg.com/profile_banners/783835886282825728/1510113250/1500x500' alt='header'></img>
            <img className={`sunnies ${isDarkMode? 'hidden' : ''}`} src='sunnies.png' alt='sunglasses'/>
            {/* Profile Pic */}
            <img className="profile_pic" src='profile_pic.jpeg' alt="profile_pic"/>
            <h1 className='fullname'>
            
                Jason Velarde 
                <VerifiedIcon 
                  className='verified_icon' 
                  color='primary' 
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
                
                <div className="nightmode_toggle">
                    <FormControlLabel
                        onChange={toggleDarkMode}
                        checked = {isDarkMode}
                        control={<MaterialUISwitch sx={{ m: 1 }}  />}
                        />
                </div>
            </h1>


            {/* Github link */}
            <div className='github_link' onClick={()=>window.open('https://github.com/jamave-bot', '_blank')}>
                @jamave-bot
                <GitHubIcon className='github_icon' fontSize='small'/>
                <div className={`verified_message ${isHovered ? '': 'hidden'}`}>This check means absolutely nothing!</div>
            </div>

            {/* Summary */}
            <div className="summary">
              Software Engineer |  
              <Typewriter
                className='typewriter'
                options={{
                  strings: [
                    'Literally Spiderman', 
                    'Cat dad', 
                    'G*mer 8)',
                    'Eepy', 
                    'Silly Goose University alum', 
                    'Saul Goodman client',
                    "twitch.tv/mavvikarp",
                    "Kageyama Shigeo's father" 
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
            

            {/* Socials */}
            <div className="socials">
                <div className='social_link' onClick={()=>window.open('https://www.linkedin.com/in/jamave/', '_blank')}>
                    <LinkedInIcon className='social_icon' fontSize='small'/>
                    LinkedIn
                </div>

                <div className='social_link' onClick={()=>window.open('https://medium.com/@jamave777', '_blank')}>
                    <AutoStoriesIcon className='social_icon' fontSize='small'/>
                    Medium
                </div>


                {/* RESUME DOWNLOAD LINK */}

                {/* <div className='social_link' onClick={downloadResume}>
                    <DownloadIcon className='social_icon' fontSize='small'/>
                    Resume
                </div> */}

            </div>

            
        </>
    )
}

export default Header