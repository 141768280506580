import React, { useState } from "react";
import '../css/ContactForm.css'

const FORM_ENDPOINT = process.env.REACT_APP_HEROTOFU_ENDPOINT; 


const ContactForm = () => {

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {

    e.preventDefault();


    const inputs = e.target.elements;

    const data = {};


    for (let i = 0; i < inputs.length; i++) {

      if (inputs[i].name) {

        data[inputs[i].name] = inputs[i].value;

      }

    }


    fetch(FORM_ENDPOINT, {

      method: 'POST',

      headers: {

        Accept: 'application/json',

        'Content-Type': 'application/json',

      },

      body: JSON.stringify(data),

    })

      .then((response) => {

        if (!response.ok) {

          throw new Error('Form response was not ok');

        }


        setSubmitted(true);

      })

      .catch((err) => {

        // Submit the form manually

        e.target.submit();

      });

  };


  if (submitted) {

    return (

      <>
        <div className="about_description">
                Thank you!
        </div>

        <div className="about_description">
                I'll be in touch soon.
        </div>

      </>

    );

  }


  return (

    <form

      action={FORM_ENDPOINT}

      onSubmit={handleSubmit}

      method="POST"

    >

        <div className="about_description">
            Hi! Feel free to message me:
            <br></br>
            Will send resume on request
        </div>

      <div className="about_description">
        <input className='contact_input' type="text" placeholder="Your name" name="name" required />

      </div>

      <div className="about_description">
        <input className='contact_input' type="email" placeholder="Email" name="email" required />

      </div>

      <div className="about_description">

        <textarea className='contact_input' placeholder="Your message" name="message" required />

      </div>

      <div className="about_description">
        <button className="submit_button" type="submit"> Send a message </button>

      </div>

    </form>

  );

};


export default ContactForm;