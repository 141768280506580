const experiences = [
    {title: 'RIA in a Box',
    description: 'Software Developer',
    features: [
        'Used Windows/Apache/MySQL/PHP to develop features for an SAAS investment advisor company',
        'Collaborated with coworkers from various departments to meet client needs',
        'Designed schema/tables/UI as well as implementing new MVC architecture to implement new add on services for certain users',
        'Worked closely with product managers to deliver a UI experience clients would be happy with',
        'Refactored code to make efficient calls to the databases'
    ],
    picture: 'ria_cover.jpg',
    dates: '2021-2023',
    }, 
    {title: 'Meetopolis',
    description: 'Software Developer',
    features: [
        'Used React, Javascript, NodeJS, MySQL, and Express to build features on both the front and back end',
    ],
    picture: 'meetopolis_cover.jpg',
    dates: '2021-2021',
    }, 
    {title: 'Gemini Physical Therapy',
    description: 'Physical Therapist Aide',
    features: [
        'Oversaw patient’s exercise activity/progress.',
        'Collaborated with the head physical therapist to design custom workout plans for each patient.',
        'Assisted head physical therapist with treatment of patients along with general clerical duties.',
        'Communicated with insurance companies to get necessary information for specific patients.'
    ],
    picture: null,
    dates: '2017-2020',
    },
  ]

export default experiences;