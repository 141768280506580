const projects = [
    {title: 'Portfolio',
    description: 'Personal website',
    features: [
        'Responsive mobile-first design',
        'Dark/Light mode'
    ],
    technologies: 'React/Material UI/JS/HTML/CSS/',
    picture: null,
    dates: '2023',
    github_link: null,
    demo_link: null,
    site_link: null,
    }, 
    {title: 'Bopify',
    description: 'Playlist curation website that is able to take music sources from Spotify and Youtube. ',
    features: [
        'Users are able to create playlists without logging into Spotify or YouTube, all they need is a song link',
        'Utilized Spotify API/YouTube API to pull specific user data, and songs, as well as external Spotify npm packages to streamline the acquisition of song URIs.',
        'Developed a user interface using React and React Context API with material-ui for styling',
        'Implemented JSON web tokens and localStorage to store encrypted user information client-side',
        'Modeled custom database schema and REST API with ActiveRecord, Postgres, and Ruby on Rails'
    ],
    technologies: 'React/Material UI/Ruby on Rails/JS/HTML/CSS/Postgres/SQL',
    picture: 'bopify_cover.jpg',
    dates: '2021',
    github_link: 'https://github.com/jamave-bot/Bopify-frontend',
    demo_link: 'https://www.youtube.com/watch?v=YQp4o6n0aO4&feature=youtu.be',
    site_link: null,
    }, 
    {title: 'Groomsmart',
    description: 'Appointment booking application that allows user to set appointments and see them on a dynamically updated calendar. ',
    features: [
        'Allowed users to set appointments for specific pets on specific dates with 2 locations',
        'Created custom database schema and REST API with ActiveRecord, Postgres, and Ruby on Rails',
        'Employed JSON web tokens and localStorage to store encrypted user information client-side',
    ],
    technologies: 'React/Ruby on Rails/JS/HTML/CSS/Postgres/SQL',
    picture: 'groomsmart_cover.jpg',
    dates: '2021',
    github_link: 'https://github.com/jamave-bot/GroomSmart',
    demo_link: 'https://youtu.be/_iPBLflZtkM',
    site_link: null,
    }, 
    {title: 'Next News',
    description: 'News app that updates dynamically to bring the latest top stories in the US. ',
    features: [
        'Implemented user interface using React, NextJS and CSS for styling.',
        'Worked with an external API to fetch relevant news articles'
    ],
    technologies: 'Nextjs/JS/HTML/CSS',
    picture: 'next_news_cover.jpg',
    dates: '2021',
    github_link: 'https://github.com/jamave-bot/next-news',
    demo_link: null,
    site_link: 'https://next-news-brown.vercel.app/',
    },
    {title: "Arnold's Fitness Tracker",
    description: 'A simple app to keep track of workouts ',
    features: [
        'Allows users to input their own custom workouts',
        'Saves exercise routines to reuse'
    ],
    technologies: 'React/CSS/Semantic Ui/JSON server',
    picture: null,
    dates: '2021',
    github_link: 'https://github.com/jamave-bot/phase-3-project-frontend',
    demo_link: null,
    site_link: null,
    },
  ]

export default projects;