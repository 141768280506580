const testimonials = [
    {title: 'Joaquin Correa (Software Engineer)',
    description: 'Jason is a quick thinker and has the skills to figure out problems efficiently. This makes him the ideal person to work [with] on projects. As a plus, he also possesses soft skills that allow him to listen to others, make a diagnosis of the situation, and agree with the team to make decisions on the go. ',
    features: null,
    picture: null,
    dates: '',
    }, 
    {title: 'Anonymous (Classmate)',
    description: "Coding wizard--he makes writing code look effortless, and he's also a genuinely nice dude!",
    features: null,
    picture: null,
    dates: '',
    }, 
    // {title: 'Anonymous',
    // description: 'Most likely to be the next CS Professor at MIT.',
    // features: null,
    // picture: null,
    // dates: '',
    // }, 
    {title: 'Anonymous (Classmate)',
    description: "Jason is gonna employ all of us one day. He has an incredible knack for development while still managing to be super chill, approachable, and humble.",
    features: null,
    picture: null,
    dates: '',
    }, 
]

export default testimonials