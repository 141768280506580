import React, {useState} from "react";
import '../css/Project.css'
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WebIcon from '@mui/icons-material/Web';
import InteractButtons from "./InteractButtons";


const Projects = ({post, selectedTab}) =>{

    const [showDetails, setShowDetails] = useState(false);

    const showFeatures = () =>{
        setShowDetails(prevMode => !prevMode)
    }

    const getThumbnailLetter = (str) =>{
        return str[0].toUpperCase();
    }

    return (
        <>
            <div className="projects">
                <div className="poster_pic">
                    <p>{getThumbnailLetter(post.title)}</p>
                </div>

                <div className="post_title">
                    {post.title} <span className="dates"> . {post.dates }</span>
                </div>

                <div className="post_description">
                     {post.description}
                    {
                        post.features ?
                            <div>
                                <span className={`show_more_button ${showDetails ? 'hidden' : '' }`} onClick={showFeatures}>
                                    (More info...)
                                </span>

                                <span className={`show_more_button ${showDetails ? '' : 'hidden' }`} onClick={showFeatures}>
                                    (Less info...)
                                </span>
                            </div>
                        :
                        ''

                    }
                </div>


                {showDetails?
                    <div className={`post_details ${post.picture? '' : 'extra_padding'}`}>
                        {post.technologies ? 
                            <div className="post_technologies">
                                [{post.technologies}]
                            </div>
                            :
                            ''
                        }
                        <ul> 
                            {post.features.map((str, index) => (
                                <li key={index}>{str}</li>
                            ))}

                        </ul>
                        <div className={`project_links ${selectedTab === 'projects'? '' : 'hidden'}`}>
                            <div className={`project_link ${post.github_link? '' : 'hidden'}`} onClick={()=>window.open(`${post.github_link}`, '_blank')}> 
                                Github<GitHubIcon className="project_icon" fontSize='small'/>
                            </div>

                            <div className={`project_link ${post.demo_link? '' : 'hidden'}`} onClick={()=>window.open(`${post.demo_link}`, '_blank')}> 
                                Demo<YouTubeIcon className="project_icon"  fontSize='small'/>
                            </div>

                            <div className={`project_link ${post.site_link? '' : 'hidden'}`} onClick={()=>window.open(`${post.site_link}`, '_blank')}> 
                                Live Site<WebIcon className="project_icon" fontSize='small'/>
                            </div>
                        </div>
                    </div>
                    :
                    ''
                }

                

                {/* Post picture if it exists */}
                {post.picture ? 
                    <div >
                        <img className="post_picture" src={post.picture} alt='Project snapshot'/>
                    </div>
                    : 
                    ''
                }


            <InteractButtons />
            </div>
            <hr />
        </>
    )    
}

export default Projects;