import '../css/About.css'
import VerifiedIcon from '@mui/icons-material/Verified';
import InteractButtons from './InteractButtons';


const About = () =>{
    return (
        <div className='about'>
            <div className='about_title'>
                <img className='profile_pic_thumbnail' src='profile_pic.jpeg' alt='thumbnail pic' />
                <div className="post_title">
                    Jason Velarde
                <VerifiedIcon className='about_verified_icon' color='primary'/>
                </div>
            </div>

            <div className="about_description">
                Hello! I'm a software engineer with years of experience working in fintech. 
            </div>
            <div className="about_description">
                This experience has given me the opportunity to work on all aspects of software development, from frontend/backend application development to devops/CICD. 
                I'm adept at working in an Agile environment, and collaborating with every team in an organization to meet our goals. I'm constantly working on improving my skills through personal project development or leetcode.  
            </div>

            <div className='about_description'>
                I believe my previous experience as a PT aide was invaluable.
                It helped me hone skills like correctly prioritizing what needs to be completed, 
                customer facing skills and hospitality, 
                and overall being present and having a clear head in the moment.
            </div>

            <div className='about_description'>
                Feel free to contact me with anything you want to talk about from professional opportunities to personal interests. 
                Some of my interests outside of coding 
                include working out, games, pets, shows, skating, video editing, streaming, anything really. I'm always looking to learn and contribute.
            </div>
            <br></br>
            <br></br>
            {/* test */}
            <InteractButtons />
            <hr />
        </div>
    )
}

export default About